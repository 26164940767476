import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/78a07ce7/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "full-container"
    }}>{`full-container`}</h2>
    <div className="full-container mb2 bg-custom">
  <h2>Test</h2>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="full-container">
  // elements
</div>
`}</code></pre>
    <h2 {...{
      "id": "container"
    }}>{`container`}</h2>
    <div className="container mb2 bg-custom">
  <h2>1280px</h2>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="container">
  // check the console
</div>
`}</code></pre>
    <h2 {...{
      "id": "small-container"
    }}>{`small-container`}</h2>
    <div className="small-container mb2 bg-custom">
  <h2>960px</h2>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="small-container">
  // To see the maxWidth of each container
</div>
`}</code></pre>
    <h2 {...{
      "id": "flex-container"
    }}>{`flex-container`}</h2>
    <div className="flex-container mb2 bg-custom">
  <h4>Test</h4>
  <h4>Test</h4>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="flex-container">
  // elements
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      